@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Jost:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body,
html {
  scroll-behavior: smooth !important;
}
::-webkit-scrollbar {
  width: 8px !important;
  height: 3px;
}
::-webkit-scrollbar-thumb {
  background-color: #41fdfe;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 2.5px !important;
}
#our-client
  .swiper-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 10px 0 !important;
}
.swiper-pagination-bullet {
  width: 13px !important;
  height: 13px !important;
  border-radius: 100% !important;
  background: transparent !important;
  opacity: 1 !important;
  border: 0.72px solid black !important;
}
#our-client .swiper-pagination-bullet {
  width: 13px !important;
  height: 13px !important;
}
.swiper-pagination-bullet-active {
  width: 13px !important;
  height: 13px !important;
  border-radius: 100% !important;
  background: #41fdfe !important;
  opacity: 1 !important;
  border: 0.72px solid #41fdfe !important;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0px !important;
  width: 100%;
}
#contact-us .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0px !important;
  width: 100%;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

input:-webkit-autofill {
  -webkit-background-clip: black !important;
  -webkit-text-fill-color: black !important;
  background-color: black !important;
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
textarea:-webkit-autofill {
  -webkit-background-clip: black !important;
  -webkit-text-fill-color: black !important;
  background-color: black !important;
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.blur-ellipse {
  -webkit-backdrop-filter: blur(150px);
}

input[type=radio] {
  accent-color: #012841;
}

@media (min-width: 1024px) {
  .blur-ellipse {
    -webkit-backdrop-filter: blur(100px);
  }
  #our-client .swiper-pagination-bullet {
    width: 16px !important;
    height: 16px !important;
  }
}
